<template>
    <div class="hello"></div>
  </template>

<script>
export default {
    name: 'HelloTest',
    created() {
        this.$router.replace(this.$route.query.redirect)
    },
}
</script>


  <style scoped>
  </style>
